interface Koordinator {
  index: number;
  name: string;
  position: string;
  department: string;
  image: string;
}

interface Sponsor {
  name: string;
  url: string;
  image: string;
}

export const KoordinatorenData: Koordinator[] = [
  {
    index: 0,
    name: "Prof. Dr. W. Korte",
    position: "CEO und Chefarzt",
    department:
      "Zentrum für Labormedizin (ZLM), Hämostase und Hämophiliezentrum, St. Gallen",
    image: "/img/wolfgang_korte_grau_neu.jpg",
  },
  {
    index: 1,
    name: "Dr. Lukas Graf",
    position: "Stv. Chefarzt",
    department:
      "Zentrum für Labormedizin (ZLM), Hämostase- und Hämophiliezentrum, St. Gallen",
    image: "/img/lukas_graf_grau.png",
  },
  {
    index: 2,
    name: "Prof. Dr. C. von Heymann, DEAA",
    position: "Chefarzt und Ärztlicher Direktor",
    department:
      "Klinik für Anästhesiologie, Intensivmedizin, Notfallmedizin und Schmerztherapie, Vivantes Klinikum im Friedrichshain, Berlin",
    image: "/img/von_heymann_grau.png",
  },
  {
    index: 3,
    name: "Dr. L. Kaufner, MSc",
    position: "Oberarzt",
    department:
      "Klinik für Anästhesiologie mit Schwerpunkt operative Intensivmedizin, Charité – Universitätsmedizin Berlin, Campus Virchow Klinikum, Berlin",
    image: "/img/kaufner_grau.png",
  },
  {
    index: 4,
    name: "PD Dr. Klamroth",
    position: "Chefarzt",
    department:
      "Klinik für Innere Medizin Angiologie und Hämostaseologie, Vivantes Klinikum im Friedrichshain, Berlin",
    image: "/img/klamroth.png",
  },
  {
    index: 5,
    name: "Jacqueline Fiore",
    position: "Koordinatorin Workshop St.&nbsp;Gallen",
    department: "",
    image: "/img/fiore.jpg",
  },
];

export const SponsorenData: Sponsor[{}] = [
  [
    // {
    //   name: "Dialine",
    //   url: "https://dialine.ch/",
    //   image: "/sponsoren/Dialine.jpg",
    // },
    // {
    //   name: "Viatris",
    //   url: "https://www.viatris.com/",
    //   image: "/sponsoren/OUS_Viatris_Logo_Verti_RGB.png",
    // },
    {
      name: "DiaSorin",
      url: "https://www.diasorin.com/home/",
      image: "/sponsoren/DiaSorin.jpg",
    },
    {
      name: "Takeda",
      url: "https://www.takeda.com/",
      image: "/sponsoren/takeda.png",
    },
    {
      name: "Novo Nordisk",
      url: "https://www.novonordisk.com/",
      image: "/sponsoren/novonordsik.png",
    },
    {
      name: "Stago",
      url: "https://www.stago.com/",
      image: "/sponsoren/Stago.jpg",
    },
    {
      name: "Roche",
      url: "https://www.roche.com/",
      image: "/sponsoren/Roche.png",
    },
    {
      name: "Swedish Orphan Biovitrum AG",
      url: "https://www.sobi.com/",
      image: "/sponsoren/Sobi.jpg",
    },
    {
      name: "Sysmex",
      url: "https://www.sysmex.ch",
      image: "/sponsoren/sysmex.png",
    },
    {
      name: "Axonlab",
      url: "https://www.axonlab.com/",
      image: "/sponsoren/Axonlab.jpg",
    },

    // {
    //   name: "Mitsubishi Tanabe Pharma",
    //   url: "https://www.mt-pharma.co.jp/",
    //   image: "/sponsoren/Mitsubishi-Tanabe-Pharma.jpg",
    // },
    // {
    //   name: "Swedish Orphan Biovitrum AG",
    //   url: "https://www.sobi.com/",
    //   image: "/sponsoren/Sobi.jpg",
    // },
  ],
  [
    {
      name: "CSL Behring",
      url: "https://www.cslbehring.com/",
      image: "/sponsoren/csl_behring.png",
    },
    {
      name: "AstraZeneca",
      url: "https://www.astrazeneca.com/",
      image: "/sponsoren/astra_zeneca.jpg",
    },
  ],
];
